import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import "./footer.css";
import signature from "../../assets/signatureWhite.svg";
import facebook from "../../assets/facebook.svg";
import instagram from "../../assets/insta.svg";
import twitter from "../../assets/twitter.svg";
import linkdin from "../../assets/linkdin.svg";
import { Link } from "react-scroll";

export default function Footer() {
  return (
    <div className='footerContainer' id="contact">
      <Container>
        <Row className='footerFirstRow'>
          <Col md={4}>
            <div>
              <img src={signature} className="signatureImg" />
            </div>
            <div className="footerSecndRow">
              <div className='nameTxt'>Phil Weber</div>
              <div className='footer1txt'>
                Author
              </div>
              <div className='footer1txt'>
                Leadership Coach
              </div>
              <div className='footer1txt'>
                Public Speaker
              </div>
            </div>
          </Col>
          <Col md={8}>
            <Row className="footerSecndRow">
              <Col md={12}>
                <div style={{ display: "flex" }}>
                  <a href='#' target="_blank">
                    <img src={facebook} className="socialSiteIcon fbook" />
                  </a>
                  <a href='https://instagram.com/coachphilweber?utm_medium=copy_link' target="_blank">
                    <img src={instagram} className="socialSiteIcon" />
                  </a>
                  <a href='https://twitter.com/CoachPhilWeber?t=E93awCRPR7txuQi6zeWpQw&s=08' target="_blank">
                    <img src={twitter} className="socialSiteIcon" />
                  </a>
                  <a href='https://www.linkedin.com/in/phil-weber-03243a3/' target="_blank">
                    <img src={linkdin} className="socialSiteIcon" />
                  </a>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div>
                  <Link to="#" className='footer2txt boldtxt arrangeimMobleview'>
                    Explore
                  </Link>
                </div>
                <div>
                  <Link to="about" spy={false} smooth={false} offset={15} duration={500} className='footer2txt pointer'>
                    About
                  </Link>
                </div>
                <div>
                  <Link to="latestNews" spy={false} smooth={false} offset={15} duration={500} className='footer2txt pointer'>
                    News
                  </Link>
                </div>
                <div>
                  <Link to="testimonials" spy={false} smooth={false} offset={15} duration={500} className='footer2txt pointer'>
                    Testimonials
                  </Link>
                </div>
              </Col>
              <Col>
                <div>
                  <Link to="books" spy={true} smooth={true} offset={50} duration={500} className='footer2txt boldtxt arrangeimMobleview pointer'>
                    Books
                  </Link>
                </div>

                <div className='footer2txt'>
                  <a target="_blank" href='https://www.amazon.com/-/es/Phil-Weber/dp/1642251984/ref=sr_1_1?crid=5NQW8EFT6RTD&keywords=how+do+you+make+them+thirsty&qid=1645568329&sprefix=how+to+make+them+thirs%2Caps%2C340&sr=8-1 '>
                    Hardcover
                  </a>
                </div>
                <div className='footer2txt'>
                  <a target="_blank" href='https://www.amazon.com/-/es/Phil-Weber/dp/1642251984/ref=sr_1_1?crid=5NQW8EFT6RTD&keywords=how+do+you+make+them+thirsty&qid=1645568329&sprefix=how+to+make+them+thirs%2Caps%2C340&sr=8-1'> E-book</a>
                </div>
                <div className='footer2txt'>
                  <a target="_blank" href="#">Audiobook</a>
                </div>
              </Col>
              <Col>
                <div className='footer3txt boldtxt arrangeimMobleview'>
                  Contact Phil
                </div>
                <div>
                  <a href="mailto:CoachPhilWeber@CoachPhilWeber.com" className='footer2txt'>CoachPhilWeber@CoachPhilWeber.com</a>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            {/* <div className='nameTxt mt-3'>Phil Weber</div>
            <div className='footer1txt'>
              Author
            </div>
            <div className='footer1txt'>
              Leadership Coach
            </div>
            <div className='footer1txt'>
              Public Speaker
            </div> */}
          </Col>
          {/* <Col>
            <div className='footer2txt boldtxt arrangeimMobleview'>
              Explore
            </div>
            <div>
              <Link to="about" spy={false} smooth={false} offset={50} duration={500} className='footer2txt'>
                About
              </Link>
            </div>
            <div>
              <Link to="latestNews" spy={false} smooth={false} offset={50} duration={500} className='footer2txt'>
                News
              </Link>
            </div>
            <div>
              <Link to="testimonials" spy={false} smooth={false} offset={50} duration={500} className='footer2txt'>
                Testimonials
              </Link>
            </div>

          </Col> */}
          {/* <Col>
            <div>
              <Link to="books" spy={true} smooth={true} offset={50} duration={500} className='footer2txt boldtxt arrangeimMobleview'>
                Books
              </Link>
            </div>

            <div className='footer2txt'>
              <a target="_blank" href='https://www.amazon.com/-/es/Phil-Weber/dp/1642251984/ref=sr_1_1?crid=5NQW8EFT6RTD&keywords=how+do+you+make+them+thirsty&qid=1645568329&sprefix=how+to+make+them+thirs%2Caps%2C340&sr=8-1 '>
                Hardcover
              </a>
            </div>
            <div className='footer2txt'>
              <a target="_blank" href='https://www.amazon.com/-/es/Phil-Weber/dp/1642251984/ref=sr_1_1?crid=5NQW8EFT6RTD&keywords=how+do+you+make+them+thirsty&qid=1645568329&sprefix=how+to+make+them+thirs%2Caps%2C340&sr=8-1'> E-book</a>
            </div>
            <div className='footer2txt'>
              <a target="_blank" href="#">Audiobook</a>
            </div>
          </Col>
          <Col md={3}>
            <div className='footer3txt boldtxt arrangeimMobleview'>
              Contact Phil
            </div>
            <div className='footer2txt'>
              CoachPhilWeber@CoachPhilWeber.com
            </div>
          </Col> */}
        </Row>
        {/* <hr className='hrClass' />

        <Row>
          <Col md={12}>
            <div className='termsConditionContainer'>
              <a href='/privacypolicy'>
                <span className='termsContxt'>Privacy Policy</span>
              </a>  
              <a  href='/termsandconditions'>
                <span className='termsContxt'>Terms and Conditions</span>
              </a> 
            </div>
          </Col>
        </Row> */}
      </Container>
    </div>
  )
}
