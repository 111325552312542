// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.latestNewstext {
    /* font-family: Roboto; */
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 44.12px;
    letter-spacing: 0em;
    text-align: center;
    color: #3F81CD;
    padding-top: 32px;
    padding-bottom: 32px;
}

.linkContainer{
    text-decoration: none;
    font-size: 16px;
    letter-spacing: 1px;
    width: 100%;
    color: #fff;
    font-weight: 700;
    line-height: 20px;
}

.linkContainer:hover{
    color: #f1f1f1;
}

.linkouterContainer{
    width: 100%;
    background: #3F81CD;
    padding: 5px 10px
}`, "",{"version":3,"sources":["webpack://./src/components/news/news.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,oBAAoB;IACpB,mBAAmB;IACnB,kBAAkB;IAClB,cAAc;IACd,iBAAiB;IACjB,oBAAoB;AACxB;;AAEA;IACI,qBAAqB;IACrB,eAAe;IACf,mBAAmB;IACnB,WAAW;IACX,WAAW;IACX,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,WAAW;IACX,mBAAmB;IACnB;AACJ","sourcesContent":[".latestNewstext {\r\n    /* font-family: Roboto; */\r\n    font-size: 40px;\r\n    font-style: normal;\r\n    font-weight: 700;\r\n    line-height: 44.12px;\r\n    letter-spacing: 0em;\r\n    text-align: center;\r\n    color: #3F81CD;\r\n    padding-top: 32px;\r\n    padding-bottom: 32px;\r\n}\r\n\r\n.linkContainer{\r\n    text-decoration: none;\r\n    font-size: 16px;\r\n    letter-spacing: 1px;\r\n    width: 100%;\r\n    color: #fff;\r\n    font-weight: 700;\r\n    line-height: 20px;\r\n}\r\n\r\n.linkContainer:hover{\r\n    color: #f1f1f1;\r\n}\r\n\r\n.linkouterContainer{\r\n    width: 100%;\r\n    background: #3F81CD;\r\n    padding: 5px 10px\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
