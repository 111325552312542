// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/speech.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.speechContainer{
    height: 736px;
    width: 100%;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});  
    display: flex;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.speechtext{
    color: #fff;
    flex-direction: column;
    font-size: 36px;
    text-align: center;
    font-weight: 700;
    width: 619px;
    letter-spacing: 1px;
    height: 191px;
    font-style: normal;
    line-height: 39.71px;
}`, "",{"version":3,"sources":["webpack://./src/components/speech/speech.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;IACX,yDAAgD;IAChD,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,4BAA4B;IAC5B,sBAAsB;IACtB,2BAA2B;AAC/B;;AAEA;IACI,WAAW;IACX,sBAAsB;IACtB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,YAAY;IACZ,mBAAmB;IACnB,aAAa;IACb,kBAAkB;IAClB,oBAAoB;AACxB","sourcesContent":[".speechContainer{\r\n    height: 736px;\r\n    width: 100%;\r\n    background-image: url(\"../../assets/speech.svg\");  \r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    background-repeat: no-repeat;\r\n    background-size: cover;\r\n    background-position: center;\r\n}\r\n\r\n.speechtext{\r\n    color: #fff;\r\n    flex-direction: column;\r\n    font-size: 36px;\r\n    text-align: center;\r\n    font-weight: 700;\r\n    width: 619px;\r\n    letter-spacing: 1px;\r\n    height: 191px;\r\n    font-style: normal;\r\n    line-height: 39.71px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
