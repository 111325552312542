// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/latestnews.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.newsContainer {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: cover;
    height: 303px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-size: 100% 100%; */
    /* padding-top: 70px; */
    background-repeat: no-repeat;
    /* background-image: url('../../assets/latestnews.svg');
    background-size: contain;
    height: 303px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: 100% 100%; */
    /* padding-top: 70px; */
}

/* .speechContainer{
    height: 736px;
    width: 100%;
    background-image: url("../../assets/speech.png");  
    display: flex;
    align-items: center;
    justify-content: center;
} */

.text {
    color: #fff;
    flex-direction: column;
    font-size: 36px;
    text-align: center;
    font-weight: 700;
    padding: 15px;
    width: 620px;
    letter-spacing: 0em;
    font-style: normal;
    /* font-family: Roboto; */
    line-height: 39.71px;
}

`, "",{"version":3,"sources":["webpack://./src/components/latestNews/latestNews.css"],"names":[],"mappings":"AAAA;IACI,yDAAoD;IACpD,sBAAsB;IACtB,aAAa;IACb,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,gCAAgC;IAChC,uBAAuB;IACvB,4BAA4B;IAC5B;;;;;;;iCAO6B;IAC7B,uBAAuB;AAC3B;;AAEA;;;;;;;GAOG;;AAEH;IACI,WAAW;IACX,sBAAsB;IACtB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,aAAa;IACb,YAAY;IACZ,mBAAmB;IACnB,kBAAkB;IAClB,yBAAyB;IACzB,oBAAoB;AACxB","sourcesContent":[".newsContainer {\r\n    background-image: url(\"../../assets/latestnews.svg\");\r\n    background-size: cover;\r\n    height: 303px;\r\n    width: 100%;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    /* background-size: 100% 100%; */\r\n    /* padding-top: 70px; */\r\n    background-repeat: no-repeat;\r\n    /* background-image: url('../../assets/latestnews.svg');\r\n    background-size: contain;\r\n    height: 303px;\r\n    width: 100%;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    background-size: 100% 100%; */\r\n    /* padding-top: 70px; */\r\n}\r\n\r\n/* .speechContainer{\r\n    height: 736px;\r\n    width: 100%;\r\n    background-image: url(\"../../assets/speech.png\");  \r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n} */\r\n\r\n.text {\r\n    color: #fff;\r\n    flex-direction: column;\r\n    font-size: 36px;\r\n    text-align: center;\r\n    font-weight: 700;\r\n    padding: 15px;\r\n    width: 620px;\r\n    letter-spacing: 0em;\r\n    font-style: normal;\r\n    /* font-family: Roboto; */\r\n    line-height: 39.71px;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
